<template>

  <DefaultCrudView
    @create-new="newApplicationInstance"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    containerLarge
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="application_instances"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.app_instance_ids="{ item }">
          <span
            v-for="(appInstance, index) in item.app_instance_ids"
            :key="appInstance"
            color="primary"
            dark
          >
            {{ appInstanceTitleById(appInstance) }}<span v-if="index != item.app_instance_ids.length - 1">, </span> 
          </span>
        </template>

        <template v-slot:item.active="{ item }">
          <v-simple-checkbox
            v-model="item.active"
            disabled
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editApplicationInstance(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" v-on:submit="save">
        <template v-slot:content>
          <DynamicFormContent :fields="fields"></DynamicFormContent>
        </template>
      </DefaultModal>

    </template>
  
  </DefaultCrudView>
</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }
</style>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import ApplicationInstanceActions from '@/store/core/application_instance/actions-types'
import ApplicationInstanceMutations from '@/store/core/application_instance/mutations-types'
import AppActions from '@/store/app/actions-types'

export default {
  name: "ApplicationInstance",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent
  },

  computed: {
    ...mapGetters('app', [
      'getIconForCoreSettingsItem'
    ]),

    ...mapGetters('core/application_instance', [
      'appInstanceTitleById'
    ]),

    ...mapState({
      fields: state => state.core.application_instance.fields,
      applications_instances: state => state.core.application_intance.applications_instances,
      headers: state => state.core.application_instance.tableHeaders,
      applications: state => state.app.applications,
      selectedApplicationInstance: state => state.core.application_instance.selectedApplicationInstance
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('apps_instance'),
          prependIcon: this.getIconForCoreSettingsItem('apps_instance'),
        },
      ]
    }
  },

  data() {
    return {
      loading: true,
      application_instances: [],
      options: {
        sortBy: ['application_instance_title'],
        sortDesc: [false],
      },
      itemsLength: 0,
      actions: [
        {
          text: "create_app_instance",
          eventToEmit: "create-new",
        }
      ],
    }
  },

  mounted() {
    this.loading = true
    this.[ApplicationInstanceMutations.SET_SELECTED_APPLICATION_INSTANCE](null)

    this.[ApplicationInstanceMutations.SET_FIELD_APPLICATION](this.applications)
      
    this.reloadData()
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('core/application_instance', [
      ApplicationInstanceActions.GET_ALL,
      ApplicationInstanceActions.SAVE_APPLICATION_INSTANCE,
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapMutations('core/application_instance', [
      ApplicationInstanceMutations.SET_FIELD_APPLICATIONS_INSTANCES,
      ApplicationInstanceMutations.SET_SELECTED_APPLICATION_INSTANCE,
      ApplicationInstanceMutations.SET_FIELD_APPLICATION,
    ]),

    newApplicationInstance() {
      this.[ApplicationInstanceMutations.SET_SELECTED_APPLICATION_INSTANCE](null)
      this.$refs.modal.openModal()
    },

    save() {
      this.$refs.modal.errorMessage = null
      this.$refs.modal.errorList = []

      this.[ApplicationInstanceActions.SAVE_APPLICATION_INSTANCE]()
        .then(() => {
          let message = this.selectedApplicationInstance ? 'ApplicationInstance edited' : 'ApplicationInstance created'
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))
          this.$refs.modal.closeModal()
          this.reloadData()
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      this.loading = true
      this.[ApplicationInstanceActions.GET_ALL](options)
        .then((result) => {
          this.application_instances = result.items
          this.itemsLength = result.total

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    editApplicationInstance(application_instance) {
      this.[ApplicationInstanceMutations.SET_SELECTED_APPLICATION_INSTANCE](application_instance)
      this.$refs.modal.openModal()
    },

    getModalTitle() {
      return this.selectedApplicationInstance ? this.$t('Edit') : this.$t('new_app_instance')
    }
  }
}

</script>